import { mergeStyleSets } from '@fluentui/react';

const getStyles = (theme) =>
  mergeStyleSets({
    defaultInput: {
      width: '100%',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.gray3}`,
      height: '32px',
    },
    viewInput: {
      width: '100%',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.gray3}`,
      height: '32px',
    },
  });

export default getStyles;
