import { ITheme } from "@theme/index";

const getStyles = (theme: ITheme) => ({
    mainWrapper: {
        root: {
            background: theme.semanticColors.bodyBackground,
            padding: `${theme.spacing.s8} ${theme.spacing.s7}`
        }
    }

})
export default getStyles;