import { Stack, Text } from '@fluentui/react';
import doctorAnimatedImg from 'assets/doctorAnimatedImg.png';
import { useFluentTheme } from '@theme/index';
import { Button, ButtonVariants,YoutubeVideo } from 'atoms/index';

import getStyles from './SignUp.styles';

export default function SignUp() {
  const theme = useFluentTheme();
  const { mainWrapper, contentSection, imgSection, teleMedicineText, signUpContentText } =
    getStyles(theme);

  const redirectToProvider = () => {
    window.open('https://app.vdodoc.com/doctor/login', '_blank');
  };

  const redirectToPatient = () => {
    window.open('https://app.vdodoc.com', '_blank');
  };

  return (
    <Stack
      horizontal
      horizontalAlign='space-between'
      tokens={{ childrenGap: theme.spacing.s5 }}
      className={mainWrapper}
    >
      <Stack wrap horizontalAlign='start' verticalAlign='center' className={contentSection}>
        <Stack
          verticalAlign='center'
          horizontalAlign='start'
          tokens={{ childrenGap: theme.spacing.s8 }}
        >
          <Text variant='sectionTitle' className={teleMedicineText}>
            Empowering Health Together: Your Digital Gateway to Personalized Care
          </Text>
          <Text variant='xLarge' className={signUpContentText}>
            Signup with seemless user journey and better experience
          </Text>
        </Stack>

        <Stack wrap horizontal gap={30} verticalAlign='end' horizontalAlign='center'>
          <Stack onClick={() => redirectToProvider()}>
            <Button>I'm a provider</Button>
          </Stack>
          <Stack onClick={() => redirectToPatient()}>
            <Button variant={ButtonVariants.Secondary}>I'm a patient</Button>
          </Stack>
        </Stack>
      </Stack>
      <Stack horizontalAlign='center' verticalAlign='center' className={imgSection}>
        {/* <img width='100%' height='100%' src={doctorAnimatedImg} alt='signup-doc-img' /> */}
        <YoutubeVideo videoId="URuaWkSVIbs"/>
      </Stack>
    </Stack>
  );
}
