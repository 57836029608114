import fontsizes from './fontsizes';
import fontweights from './fontweights';
import { ITypographies } from './typography.types';

const typography: ITypographies = {
  pageTitle: {
    fontSize: fontsizes.xxxl,
    fontWeight: fontweights.semibold,
    lineHeight: '68px',
  },
  sectionTitle: {
    fontSize: fontsizes.xxl,
    fontWeight: fontweights.semibold,
    lineHeight: '28px',
  },
  large: {
    fontSize: fontsizes.l,
    fontWeight: fontweights.semibold,
    lineHeight: '22px',
  },
  largeBold: {
    fontSize: fontsizes.l,
    fontWeight: fontweights.bold,
    lineHeight: '22px',
  },
  medium: {
    fontSize: fontsizes.m,
    fontWeight: fontweights.normal,
    lineHeight: '20px',
  },
  mediumSemibold: {
    fontSize: fontsizes.m,
    fontWeight: fontweights.semibold,
    lineHeight: '20px',
  },
  mediumBold: {
    fontSize: fontsizes.m,
    fontWeight: fontweights.bold,
    lineHeight: '20px',
  },
  small: {
    fontSize: fontsizes.s,
    fontWeight: fontweights.normal,
    lineHeight: '16px',
  },
  smallSemibold: {
    fontSize: fontsizes.s,
    fontWeight: fontweights.semibold,
    lineHeight: '16px',
  },
  smallBold: {
    fontSize: fontsizes.s,
    fontWeight: fontweights.bold,
    lineHeight: '16px',
  },
};

export default typography;
