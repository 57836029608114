import { concat, map } from 'lodash';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REDUCER_PATH, TAGS } from "@utils/constants";

const baseURL = "https://api.vdodoc.com"

const baseQuery = (args: any, api: any, extraOptions: any) =>
    fetchBaseQuery({
        baseUrl: baseURL || args.baseUrl,
        prepareHeaders: async (headers) => {
            // Add access token
            let token;

            headers.set('Authorization', `Bearer ${token}`);

            headers.set('accept', 'application/json');

            return headers;
        },
    })(args, api, extraOptions);


const baseQueryWithReauth = () => async (args: any, api: any, extraOptions: any) => {
    const result = await baseQuery(args, api, extraOptions);
    return result;
};

const api = createApi({
    reducerPath: REDUCER_PATH,
    baseQuery: baseQueryWithReauth(),
    refetchOnReconnect: true,
    tagTypes: concat(
        Object.values(TAGS),
        map(Object.values(TAGS), (tag: string) => `Data_${tag}`)
    ),
    endpoints: () => ({}),
});
export default api;
