/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { Text as FluentText, ITextProps } from '@fluentui/react';
import * as React from 'react';
import { ITypographies } from '../../theme/foundations/typography';

interface TextProps extends Omit<ITextProps, 'variant'> {
  variant?: keyof ITypographies;
}

const Text: React.FunctionComponent<TextProps> = (props: TextProps) => {
  const { variant = 'medium', children, ...others } = props;
  return (
    <FluentText {...others} variant={variant as any}>
      {children}
    </FluentText>
  );
};

export default Text;
