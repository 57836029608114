import React from 'react';
import { TextField } from '@fluentui/react';
import { useFluentTheme } from '@theme/index';
import getStyles from './Input.styles';
import { INPUT_VALIDATION_REGEX } from '@utils/constants';
export const InputVariants = {
  Default: 'Default',
  Password: 'Password',
  View: 'View',
};

const Input = ({ variant = InputVariants.Default, value, onChange, ...restProps }) => {
  const theme = useFluentTheme();
  const styles = getStyles(theme);

  const defaultStyles = {
    fieldGroup: {
      background: 'transparent',
    },
    errorMessage: {
      color: theme.palette.red1,
      marginTop: '4px',
    },
  };
  const viewStyles = {
    fieldGroup: {
      background: theme.palette.white,
    },
  };
  const onInputChange = (e) => {
    const { name, validateInput } = restProps;

    const isValid =
      typeof validateInput === 'function'
        ? validateInput(e.target.value)
        : INPUT_VALIDATION_REGEX[name]?.test(e.target.value);

    console.log(' onInputChange e.target.value', e.target.value, isValid);

    onChange(e, { name, value: e.target.value, error: !isValid });
  };

  if (variant === InputVariants.View)
    return (
      <TextField
        borderless
        readOnly
        className={`${styles.viewInput}`}
        styles={viewStyles}
        value={value}
        onChange={(_e, newValue) => {
          const { name, error } = restProps;
          onChange(_e, { name, value: newValue, error });
        }}
        onBlur={onInputChange}
        {...restProps}
      />
    );
  return (
    <TextField
      borderless
      className={`${styles.defaultInput}`}
      styles={defaultStyles}
      value={value}
      onChange={(_e, newValue) => {
        const { name, error } = restProps;
        onChange(_e, { name, value: newValue, error });
      }}
      onBlur={onInputChange}
      {...restProps}
    />
  );
};
export default Input;
