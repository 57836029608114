import React from 'react';
import { DefaultButton, IButtonProps } from '@fluentui/react';
import { useFluentTheme } from '@theme/index';
import getStyles from './Button.styles';

export enum ButtonVariants {
  Default = 'Default',
  Secondary = 'Secondary',
}

type ButtonProps = IButtonProps & {
  variant?: ButtonVariants;
  className?: string;
  children: React.ReactNode;
};

const Button: React.FunctionComponent<ButtonProps> = ({
  variant = ButtonVariants.Default,
  className,
  children,
  styles = {},
  onClick,
  ...rest
}: ButtonProps) => {
  console.log('variant', variant);
  const theme = useFluentTheme();
  const classes = getStyles(theme);
  if (variant === ButtonVariants.Secondary)
    return (
      <DefaultButton
        className={`${classes.secondaryButton} ${className}`}
        styles={styles}
        onClick={onClick}
        {...rest}
      >
        {children}
      </DefaultButton>
    );
  return (
    <DefaultButton
      className={`${classes.defaultButton} ${className}`}
      styles={styles}
      onClick={onClick}
      {...rest}
    >
      {children}
    </DefaultButton>
  );
};
export default Button;
