import { ITheme } from "@theme/foundations";
import { mergeStyleSets } from "@fluentui/react";

const getStyles = (theme: ITheme) =>
    mergeStyleSets({
        mainWrapper: {
            width: '100%',
            justifyContent: 'center',
            '@media(max-width: 992px)': {
                flexDirection: 'column',
                justifyContent: 'space-between',
            }
        },
        contentSection: {
            maxWidth: "500px"
        },
        imgSection: {
            maxWidth: "750px",
            '@media(max-width: 992px)': {
                marginTop: theme.spacing.s5,
                maxWidth: "400px",
                marginLeft: "0 !important",
            }
        },
        teleMedicineText: {
            textAlign: "left",
            lineHeight: '40px',
            '@media(max-width: 992px)': {
                fontSize: theme.fontSizes.xl,
                lineHeight: '28px',
            }
        },
        signUpContentText: {
            textAlign: "left",
            color: theme.palette.gray1,
            marginBottom: theme.spacing.s5,
            '@media(max-width: 992px)': {
                marginTop: `${theme.spacing.s3} !important`,
                fontSize: theme.fontSizes.m,
            }
        }
    });

export default getStyles;