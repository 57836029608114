import { ISizes } from './spacing.types';

const sizes: ISizes = {
  sm: '300px',
  md: '500px',
  lg: '800px',
  xlg: '1140px',
};

export default sizes;
