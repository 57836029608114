import api from '../../store/api';

export const inquiryApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendInquiry: build.mutation({
      query: (payload) => ({
        method: 'POST',
        url: `/service/inquiry`,
        body: payload,
      }),
      providesTags: () => [],
    }),
  }),
});

export const { useSendInquiryMutation } = inquiryApi;
