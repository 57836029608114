import { createTheme, useTheme } from '@fluentui/react';
import {
    palette,
    semanticColors,
    fonts,
    fontWeights,
    fontSizes,
    spacing,
    sizes,
    ITheme,
    zIndex,
} from './foundations';

const t = {
    palette,
    semanticColors,
    fonts,
    fontWeights,
    fontSizes,
    spacing,
    sizes,
    zIndex,
};

const theme = createTheme(t);

const useFluentTheme = (): ITheme => {
    const v: any = useTheme();
    return v;
};

export default theme;
export { useFluentTheme };
export type { ITheme };