import color from './basecolors';

const primary = color.brand0;

export default {
  // Semantic colors

  // Primary
  primary,

  // States
  primaryHover: color.brand5,
  primaryActive: color.brand6,

  // Background
  backgroundPrimary: color.brand1,

  // Link
  linkPrimary: primary,

  // Button
  buttonPrimary: primary,

  // Base
  bluePrimary: color.blue6, // checkbox, radio, toggle
  bluePrimaryHover: color.blue7,

  blueBackground: color.blue11, // blue11

  greenBackground: color.green1, // green1
  greenPrimary: color.green4, // green4
  greenForeground: color.green8, // green8

  orangeBackground: color.orange1, // orange1
  orangePrimary: color.orange3, // orange4
  orangeForeground: color.orange8, // orange8

  redBackground: color.red1, // red1
  redPrimary: color.red3, // red4
  redForeground: color.red8, // red8

  purpleBackground: color.purple1, // purple1
  purplePrimary: color.purple5, // purple4
  purpleForeground: color.purple8, // purple8

  grayBackground: color.gray14, // gray14

  // Status
  status: color.gray4,

  // Text
  textPrimary: color.gray13,
  textSecondary: color.gray7,

  // Icon
  icon: color.gray7,
  iconButton: color.gray6,

  // Input
  input: color.gray3,
  inputHover: color.gray4,
  inputActive: primary,

  // Misc
  border: color.transparent3,
  separator: color.transparent3,
  disabled: color.gray4,

  success: color.green4,
  warning: color.orange3,
  error: color.red3,

};
